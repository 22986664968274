import React, {useEffect, useState, useRef} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { CSSPlugin } from 'gsap/CSSPlugin';
import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

import HomePageFeaturesSection from '../components/HomePageFeaturesSection';
import {HeaderHomePage} from '../components/Header';
import Footer from '../components/Footer';

import { ReactComponent as ArrowRight } from '../assets/arrow-right.svg';

import '../static/homePage.css';

const HomePage = () => {
  let animationResponsiveness = gsap.matchMedia();
  const canvasRef = useRef();

    useEffect(() => {
      gsap.registerPlugin(ScrollTrigger, CSSPlugin, CSSRulePlugin);

      if (window.innerWidth > 700) {
        homePageBiggerScreensConfiguration();
      } else {
        homePageSmallerScreensConfiguration();
      }
        
    }, []);

    let homePageBiggerScreensConfiguration = () => {
      const stopPointForModel = document.querySelector("#features-section-section").offsetTop + (document.querySelector("#features-section-section").clientHeight/100)*25;
      const modelContainer = document.getElementById('3d-model-container');

      const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, canvas: canvasRef.current });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.shadowMap.enabled = true;
      renderer.shadowMapSoft = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;

      const scene = new THREE.Scene();
      scene.rotation.set(0.1, 0, 0)

      const camera = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(0, -0.02, 0.65);
      

      const geometry = new THREE.PlaneGeometry( 0.5, 0.5 );

      const material = new THREE.ShadowMaterial();
      material.opacity = 0.6;

      const plane = new THREE.Mesh( geometry, material );
      plane.position.x = 0;
      plane.position.y = -0.11;
      plane.position.z = 0;
      plane.rotation.x = 4.75;
      plane.receiveShadow = true;
      scene.add( plane );

      const spotLight = new THREE.SpotLight(0xffffff,  45, 100, 0.22, 1);
      spotLight.position.set(0, 1.3, 5);
      spotLight.angle = 80;
      scene.add(spotLight);

      const light = new THREE.DirectionalLight( 0xffffff, 3 );
      light.position.set( 0, 0.8, 0.12 );
      light.castShadow = true;
      scene.add( light );

      const loader = new GLTFLoader().setPath('/media/models/');
      // const loader = new GLTFLoader().setPath('/models/');
      let mesh;
      loader.load('test2.glb', (gltf) => {
      mesh = gltf.scene;

      mesh.traverse((child) => {
          if (child.isMesh) {
          child.castShadow = true;
          child.receiveShadow = true;
          }
      });

      scene.add(mesh);

      }, ( xhr ) => {
          console.log('')
      },);

      window.addEventListener('resize', () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });

      const halfWindowWidth = window.innerWidth/2
      const halfWindowHeight = window.innerHeight/2
      window.addEventListener("mousemove", (e) => {
        gsap.to(mesh?.rotation, {
            y: (e.clientX - halfWindowWidth) / 9000,
            x: (e.clientY - halfWindowHeight) / 9000
        });
      });

      function animate() {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      }

      animate();

      //Animations

      //3D Phone Animations
      animationResponsiveness.add("(min-width: 1000px)", () => {
        let toFirstSectionAnimation = gsap.timeline({
          scrollTrigger: {
            trigger: "#hero-section",
            start: "bottom bottom",
            //endTrigger: "#features-section",
            end: "bottom top",
            scrub: true
          },
        });
        toFirstSectionAnimation.to(scene.rotation, {y: -0.4,}, 0);
        toFirstSectionAnimation.to(camera.position, {x: -0.1, y: 0.005, z: 0.55}, 0);

        let toSecondSectionAnimation = gsap.timeline({
          scrollTrigger: {
            trigger: "#about-us-section",
            start: "120% bottom",
            end: "120% top",
            scrub: true
          }
        });
        toSecondSectionAnimation.to(scene.rotation, {y: 0.4,}, 0);
        toSecondSectionAnimation.to(camera.position, {x: 0.13, y: 0.005, z: 0.55}, 0);

        let toThirdSectionAnimation = gsap.timeline({
          scrollTrigger: {
            trigger: "#problems-we-solve-section",
            start: "120% bottom",
            end: "120% top",
            scrub: true
          }
        });
        toThirdSectionAnimation.to(scene.rotation, {y: -0.4,}, 0);
        toThirdSectionAnimation.to(camera.position, {x: -0.1, y: -0.02, z: 0.65}, 0);
      });

      animationResponsiveness.add("(min-width: 100px) and (max-width: 1200px)", () => {
        gsap.set("canvas", {position: "absolute", top: 0});
        let toFirstSectionAnimation = gsap.timeline({
          scrollTrigger: {
            trigger: "#hero-section",
            start: "bottom bottom",
            //endTrigger: "#features-section",
            end: "bottom top",
            scrub: true
          },
        });
        toFirstSectionAnimation.to(camera.position, {x: 0, y: 0.005, z: 0.75}, 0);
      });

      animationResponsiveness.add("(min-width: 1201px)", () => {
        gsap.to("canvas", {
          position: "absolute",
          top: stopPointForModel,
          scrollTrigger: {
            trigger: "#features-section-container",
            start: "25% top",
            end: "25% top",
            scrub: true
          }
        })
      });
      //Hero Section Text Animation
      gsap.to("#hero-section-text", {
        opacity: 0,
        scrollTrigger: {
          trigger: "#hero-section",
          start: "5% top",
          end: "bottom 90%",
          scrub: true
        }
      }, 0);

      gsap.to("#hero-section-call-to-action", {
        opacity: 0,
        scrollTrigger: {
          trigger: "#hero-section",
          start: "7% top",
          end: "bottom 88%",
          scrub: true
        }
      }, 0);

      //About Us Section Animation
      //When entering the section
      gsap.to('#about-us-section-container', {
        x: 0,
        y: 200,
        opacity: 1,
        scrollTrigger: {
          trigger: "#about-us-section",
          start: "20% 90%",
          end: "60% 90%",
          scrub: true
        }
      });
      //When leaving the section
      gsap.to('#about-us-section-container', {
        x: "-100%",
        opacity: 0,
        scrollTrigger: {
          trigger: "#about-us-section",
          start: "bottom 65%",
          end: "bottom 50%",
          scrub: true
        }
      });

      gsap.set('#about-us-section-title', {x: "-100%", opacity: 0});
      gsap.to("#about-us-section-title", {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#about-us-section",
          start: "20% 90%",
          end: "50% 90%",
          scrub: true
        }
      });

      gsap.set('#about-us-section-text', {x: "-100%", opacity: 0});
      gsap.to("#about-us-section-text", {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#about-us-section",
          start: "25% 90%",
          end: "55% 90%",
          scrub: true
        }
      });

      gsap.set('#about-us-section-call-to-action', {y: 0, opacity: 0.8});
      gsap.to("#about-us-section-call-to-action", {
        x: 0,
        y: 50,
        opacity: 1,
        scrollTrigger: {
          trigger: "#about-us-section",
          start: "70% 90%",
          end: "90% 90%",
          scrub: true
        }
      });

      //Problems We Solve Section
      gsap.to("#problems-we-solve-section-container", {
        y: 200,
        scrollTrigger: {
          trigger: "#problems-we-solve-section",
          start: "30% 90%",
          end: "70% 90%",
          scrub: true
        }
      });

      gsap.to('#problems-we-solve-section-container', {
        x: "100%",
        opacity: 0,
        scrollTrigger: {
          trigger: "#problems-we-solve-section",
          start: "bottom 65%",
          end: "bottom 35%",
          scrub: true
        }
      });

      gsap.set("#problems-we-solve-section-title", {x: "100%", opacity: 0});
      gsap.to("#problems-we-solve-section-title", {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#problems-we-solve-section",
          start: "30% 90%",
          end: "60% 90%",
          scrub: true
        }
      });

      gsap.set("#problems-we-solve-section-text", {x: "100%", opacity: 0});
      gsap.to("#problems-we-solve-section-text", {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#problems-we-solve-section",
          start: "35% 90%",
          end: "65% 90%",
          scrub: true
        }
      });

      //Features Section Animation
      let featuresSectionHeight = document.getElementById("features-section-section").offsetHeight;
      let featuresContainerHeight = document.getElementById("features-section-container").offsetHeight;
      let featuresSectionContainerHeightDifference = featuresSectionHeight - featuresContainerHeight;

      gsap.to("#features-section-container", {
        y: featuresSectionContainerHeightDifference,
        opacity: 1,
        scrollTrigger: {
          trigger: "#features-section",
          start: "20% 90%",
          end: "60% 90%",
          scrub: true
        }
      });

      gsap.set("#features-section-title", {x: "-100%", opacity: 0})
      gsap.to("#features-section-title", {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#features-section",
          start: "3% 90%",
          end: "15% 90%",
          scrub: true
        }
      });

      gsap.set("#features-section-buttons", {x: "-100%", opacity: 0})
      gsap.to("#features-section-buttons", {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#features-section",
          start: "5% 90%",
          end: "15% 90%",
          scrub: true
        }
      });

      gsap.set("#features-section-first-feature", {x: "-100%", opacity: 0})
      gsap.to("#features-section-first-feature", {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#features-section",
          start: "5% 90%",
          end: "15% 90%",
          scrub: true
        }
      });

      gsap.set("#features-section-second-feature", {x: "-100%", opacity: 0})
      gsap.to("#features-section-second-feature", {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#features-section",
          start: "8% 90%",
          end: "18% 90%",
          scrub: true
        }
      });

      gsap.set("#features-section-third-feature", {x: "-100%", opacity: 0})
      gsap.to("#features-section-third-feature", {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#features-section",
          start: "11% 90%",
          end: "21% 90%",
          scrub: true
        }
      });

      ScrollTrigger.create({
        trigger: "#footer-section",
        start: "50% bottom",
        end: "bottom top",
        scrub: true,
        animation: gsap.to(CSSRulePlugin.getRule('.clipped-div:before'), {
          borderRadius: "25%"
        })
      });
    }

    let homePageSmallerScreensConfiguration = () => {
      //Hero Section Text Animation
      gsap.to("#hero-section-text", {
        scale: 0.8,
        opacity: 0,
        scrollTrigger: {
          trigger: "#hero-section",
          start: "5% top",
          end: "bottom 90%",
          scrub: true
        }
      }, 0);

      gsap.to("#hero-section-call-to-action", {
        scale: 0.8,
        opacity: 0,
        scrollTrigger: {
          trigger: "#hero-section",
          start: "7% top",
          end: "bottom 70%",
          scrub: true
        }
      }, 0);

      gsap.to(".hero-section-phone-title-container", {
        scale: 1.2,
        scrollTrigger: {
          trigger: "#hero-section",
          start: "top top",
          end: "bottom center",
          scrub: true
        }
      }, 0);

      // gsap.to('#about-us-section-container', {
      //   bottom: 0,
      //   y: 0,
      //   scrollTrigger: {
      //     trigger: "#about-us-section",
      //     start: "top 20%",
      //     end: "bottom center",
      //     scrub: true
      //   }
      // });

      // gsap.to('#problems-we-solve-section-container', {
      //   bottom: 0,
      //   y: 0,
      //   scrollTrigger: {
      //     trigger: "#problems-we-solve-section",
      //     start: "top 20%",
      //     end: "bottom center",
      //     scrub: true
      //   }
      // });

      // //Features Section Animation
      // gsap.to("#features-section-container", {
      //   bottom: 0,
      //   y: 0,
      //   scrollTrigger: {
      //     trigger: "#features-section",
      //     start: "top 20%",
      //     end: "bottom center",
      //     scrub: true
      //   }
      // });
    }

  return (
    <div className='overflow-hidden page-menu-wrapper' id='home-page-container'>
      <HeaderHomePage />

        <div className='z-2 m-0 p-0' style={{height: '100vh', width: '100vw'}} id='model-container'>
          <canvas ref={canvasRef} className='w-100' />
          <div className='h-50 d-flex flex-column justify-content-center align-items-center hero-section-phone-title-container'>
            <p className='text-white hero-section-title'>SERVIN</p>
            <p className='text-white hero-section-subtitle'>We Make Service Easy</p>
          </div>
        </div>

        <div className='col-12 mb-3 position-absolute bottom-0 d-flex justify-content-center align-items-end' style={{minHeight: '100vh'}} id='hero-section'>
          <div className='col-11 col-md-8 col-lg-4 d-flex flex-column justify-content-center align-items-center'>
            <p className='fs-5 text-center font-inter' id='hero-section-text'>Servin is a platform which helps to find performers and clients in a lot of services.</p>
            <a className='btn bg-cyan rounded-pill' id='hero-section-call-to-action'>Get the app</a>
          </div>
        </div>

        <div className='container-fluid d-flex justify-content-center align-items-start position-relative z-2 my-from-md-4' style={{minHeight: '100vh'}} id='about-us-section'>
          <div className='col-10 background-blured'>
            <div className='col-10 col-md-9 col-xl-6 hero-section-position-absolute-after-md' id='about-us-section-container'>
              <h1 className='title-text mb-2 font-anton' id='about-us-section-title'>Get services became even more easier</h1>
              <p className='responsive-text-2 responsive-text-before-md-3 font-inter-light mt-3 mb-5 text-justify-spread-md' id='about-us-section-text'>Servin is an app that completely changes the concept of receiving services. With us, masters, people with good experience in providing certain services, will be able to increase their customer base, and customers, in turn, will be able to easily and quickly find reliable masters who can solve their problem!</p>
              <a href='/' className='btn bg-cyan dark-text text-center fs-3 font-anton ps-4 pe-3 py-2 rounded-4' id='about-us-section-call-to-action'>About Us <ArrowRight className='ms-3' /></a>
            </div>
          </div>
        </div>

        <div className='container-fluid d-flex justify-content-center align-items-center position-relative px-0 z-2 my-from-md-4' id='problems-we-solve-section' style={{minHeight: '110vh'}}>
          <div className='col-10 background-blured d-flex justify-content-end'>
            <div className='col-10 col-md-9 col-xl-6 hero-section-position-absolute-after-md' id='problems-we-solve-section-container'>
              <h1 className='title-text mb-2 font-anton' id='problems-we-solve-section-title'>Problems we solve?</h1>
              <p className='responsive-text-2 responsive-text-before-md-3 font-inter-light mt-3 mb-5 text-justify-spread-md' id='problems-we-solve-section-text'>Servin is a powerful tool that greatly simplifies the search and ordering of home and other services, making the process fast, convenient and transparent. The platform also creates new opportunities for professionals, offering them a simple and efficient way to enter the market.</p>
              <div className='w-100 d-flex justify-content-end'>
                <a href='/' className='bg-cyan dark-text text-center fs-3 font-anton ps-4 pe-3 py-2 rounded-4'>Read More <ArrowRight className='ms-3' /></a>
              </div>
            </div>
          </div>
        </div>

        <div className='container d-flex justify-content-start align-items-start' style={{minHeight: '100vh'}} id='features-section'>
          <HomePageFeaturesSection />
        </div>

      <Footer />

    </div>
  )
}

export default HomePage