import React, {useEffect, useState} from 'react';
import axios from 'axios';

import {HeaderHomePage} from '../components/Header';

import { ReactComponent as BackgroundSVG } from '../assets/contacts-svg/contactsBackground.svg';

import '../static/contactsPage.css';

const ContactsPage = () => {
  const [chosenForm, setChosenForm] = useState(1);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  let sendSupportLetter = () => {
    let data = {
      'full_name': firstName + ' ' + lastName,
      'email': email,
      'phone': phone,
      'message': message
    }
    axios.post("/contacts/support/", data).then((response) => {
      console.log(response);
    });
  };

  return (
    <div>
      <HeaderHomePage />

      <div className='container-fluid d-flex flex-column justify-content-center align-items-end background-gradient' style={{minHeight: '100vh'}}>
        <div className='col-11 col-md-10 col-lg-7 position-absolute top-0 start-0 z-0 mt-5 pt-5' id='contacts-svg-container'>
          <BackgroundSVG />
        </div>

        <div className='col-12 col-md-8 col-lg-7 d-flex flex-column justify-content-evenly align-items-center z-2'>
          <div className='col-12 col-md-10 col-lg-9 col-xl-8 bg-white rounded-3 d-flex flex-column align-items-center contacts-form-container'>
            <div className='m-3 mt-5'>
              <a className={`bg-white font-inter-semibold text-dark fs-6 px-4 py-2 mx-2 rounded-3 button-shadow cursor-pointer contacts-option ` + (chosenForm == 1 ? 'choosen-form-section-button' : null)} onClick={(e) => setChosenForm(1)}>Support</a>
              <a className={`bg-white font-inter-semibold text-dark fs-6 px-4 py-2 mx-2 rounded-3 button-shadow cursor-pointer contacts-option ` + (chosenForm == 2 ? 'choosen-form-section-button' : null)} onClick={(e) => setChosenForm(2)}>Cooperation</a>
              <a className={`bg-white font-inter-semibold text-dark fs-6 px-4 py-2 mx-2 rounded-3 button-shadow cursor-pointer contacts-option ` + (chosenForm == 3 ? 'choosen-form-section-button' : null)} onClick={(e) => setChosenForm(3)}>Investor</a>
            </div>

            <form className='h-auto py-4 mt-4 d-flex flex-wrap justify-content-evenly align-items-start row-gap-3 contacts-form'>
                <div className='col-11 col-md-5 pt-2 my-3 border border-1 border-secondary rounded-2 position-relative'>
                  <p className='font-inter fw-light fs-6 px-1 contacts-form-title'>First Name</p>
                  <input type='text' className='w-100  mt-1 fs-6 fw-light ps-2 border-0 rounded-2' onChange={(e) => {setFirstName(e.target.value)}} required />
                </div>

                <div className='col-11 col-md-5 pt-2 my-3 border border-1 border-secondary rounded-2 position-relative'>
                  <p className='font-inter fw-light fs-6 px-1 contacts-form-title'>Last Name</p>
                  <input type='text' className='w-100  mt-1 fs-6 fw-light ps-2 border-0 rounded-2' onChange={(e) => {setLastName(e.target.value)}} required />
                </div>

                <div className='col-11 col-md-5 pt-2 my-3 border border-1 border-secondary rounded-2 position-relative'>
                  <p className='font-inter fw-light fs-6 px-1 contacts-form-title'>Email</p>
                  <input type='email' className='w-100 mt-1 fs-6 fw-light ps-2 border-0 rounded-2' onChange={(e) => {setEmail(e.target.value)}} required />
                </div>

                <div className='col-11 col-md-5 pt-2 my-3 border border-1 border-secondary rounded-2 position-relative'>
                  <p className='font-inter fw-light fs-6 px-1 contacts-form-title'>Phone</p>
                  <input type='text' className='w-100  mt-1 fs-6 fw-light ps-2 border-0 rounded-2' onChange={(e) => {setPhone(e.target.value)}} required />
                </div>
                
                <div className='col-11 px-2 pt-2 my-3 border border-1 border-secondary rounded-2 position-relative'>
                  <p className='font-inter fw-light fs-6 px-1 contacts-form-title' style={{top: "-10%"}}>Message</p>
                  <textarea className='w-100 mt-1 fs-6 border-0 rounded-2' style={{maxHeight: "30vh", minHeight: "15vh"}} onChange={(e) => {setMessage(e.target.value)}}></textarea>
                </div>

                <div className='col-11 d-flex justify-content-center justify-content-md-end submit-form-button-container'>
                  <button className='fs-5 font-anton text-dark px-4 py-1 border-0 rounded-2 cursor-pointer overflow-hidden submit-form-button' onClick={(e) => sendSupportLetter()}>Send Application</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactsPage